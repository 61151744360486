import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function ThankYouPage() {
  return (
    <Layout>
      <Seo title="Accessibility" />
      <Hero />
      <Policy />
    </Layout>
  )
}

function Hero() {
  return (
    <section className="relative bg-gray-50 overflow-hidden">
      <div
        className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative h-full max-w-7xl mx-auto">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
        </div>
      </div>

      <div className="relative my-16 mx-auto max-w-7xl px-4 sm:my-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="xl:inline">Web</span>{" "}
            <span className="text-indigo-600 xl:inline">Accessibility</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Qwarkle is committed to making its products accessible to all users
            and ensuring that they meet or exceed the requirements of Web
            Content Accessibility Guidelines 2.0 and Section 508 of the
            Rehabilitation Act.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8"></div>
        </div>
      </div>
    </section>
  )
}

function Policy() {
  const statement = [
    {
      heading: "Our policy ",
      content:
        "We’re committed to accessibility. It is our policy to ensure that everyone, including persons with disabilities, has full and equal access to our digital offerings.",
    },
    {
      heading: "We welcome feedback",
      content:
        "Email us at seth@rawiedemann.com with any questions or comments you have.",
    },
    {
      heading: "Our standards",
      content:
        "Qwarkle strives to conform to the following accessibility standards: Web Content Accessibility Guidelines 2.0, Revised Section 508 standards published January 18, 2017 and corrected January 22, 2018",
    },
    {
      heading: "Compatibility",
      content:
        "Qwarkle products are compatible with at least N-1 versions and above of browsers such as Internet Explorer, Safari, Mozilla Firefox, and Google Chrome. ",
    },
    {
      heading: "Testing",
      content:
        "Qwarkle engages in regular testing and manual audits to assess and improve accessibility.",
    },
    {
      heading: "Qwarkle product accessibility features",
      content: "compatibility with assistive technology",
    },
  ]
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:py-28 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              Accessibility Statement
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              If you require assistance or wish to report an issue related to
              the accessibility of any Qwarkle product, please contact us.
              Please include the web address or URL and the specific problems
              you have encountered.
            </p>
            <p className="text-lg mt-4">
              <a
                href="mailto:seth@rawiedemann.com"
                className="font-medium  text-indigo-600 hover:text-indigo-500"
              >
                Report an accessibility issue
              </a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-12">
              {statement.map((faq, index) => (
                <div key={index}>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    {faq.heading}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {faq.content}
                  </dd>
                </div>
              ))}
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Qwarkle product accessibility features
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  <p>
                    All Qwarkle products provide compatibility with the
                    following assistive technologies:
                  </p>
                  <ul className="list-disc px-8">
                    <li>
                      screen magnifiers, and other visual reading assistants,
                      which are used by people with visual, perceptual and
                      physical print disabilities to change text font, size,
                      spacing, color, synchronization with speech, etc. in order
                      to improve the visual readability of rendered text and
                      images;
                    </li>
                    <li>
                      screen readers, which are used by people who are blind to
                      read textual information through synthesized speech or
                      braille;
                    </li>
                    <li>
                      text-to-speech software, which is used by some people with
                      cognitive, language, and learning disabilities to convert
                      text into synthetic speech;
                    </li>
                    <li>
                      speech recognition software, which may be used by people
                      who have some physical disabilities;
                    </li>
                    <li>
                      alternative keyboards, which are used by people with
                      certain physical disabilities to simulate the keyboard
                      (including alternate keyboards that use head pointers,
                      single switches, sip/puff and other special input
                      devices.);
                    </li>
                    <li>
                      alternative pointing devices, which are used by people
                      with certain physical disabilities to simulate mouse
                      pointing and button activations.
                    </li>
                  </ul>
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Resources
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  For more information on web accessibility and legal
                  requirements, here are helpful resources:
                  <ul className="list-disc px-8">
                    <li>
                      <a
                        className="text-indigo-600 hover:text-indigo-700 font-medium"
                        href="https://ada.gov/"
                      >
                        ADA.gov
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-indigo-600 hover:text-indigo-700 font-medium"
                        href="https://section508.gov/"
                      >
                        Section508.gov
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-indigo-600 hover:text-indigo-700 font-medium"
                        href="https://accessible.org/"
                      >
                        Accessible.org
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-indigo-600 hover:text-indigo-700 font-medium"
                        href="https://www.w3.org/WAI/"
                      >
                        W3.org/WAI
                      </a>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
